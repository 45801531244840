import styled from 'styled-components'
import { Layout, Menu } from 'antd'

import { BoxShadow, Color, FontSize } from '../../../styles'
import { SIDER_WIDTH, SIDER_WIDTH_COLLAPSED } from '../../../constants'

const { Sider } = Layout

const { Item } = Menu

// ********** Styled Sider ********** //

type StyledSiderProps = {
  collapsed: boolean
  $bcColor?: string
}

export const StyledSider = styled(Sider)<StyledSiderProps>`
  background-color: ${(props) => (props.$bcColor ? props.$bcColor : Color.lightBlue)};

  .ant-menu {
    background-color: ${(props) => (props.$bcColor ? props.$bcColor : Color.lightBlue)} !important;
    box-shadow: ${BoxShadow.sider} !important;
  }

  & .ant-menu-inline {
    height: 100% !important;
  }

  & .ant-menu-inline-collapsed {
    height: 100% !important;
  }

  &.ant-layout-sider {
    width: ${(props) => (props.collapsed ? SIDER_WIDTH_COLLAPSED : SIDER_WIDTH)} !important;
    flex: 0 0 ${(props) => (props.collapsed ? SIDER_WIDTH_COLLAPSED : SIDER_WIDTH)} !important;
    max-width: ${(props) => (props.collapsed ? SIDER_WIDTH_COLLAPSED : SIDER_WIDTH)} !important;
    min-width: ${(props) => (props.collapsed ? SIDER_WIDTH_COLLAPSED : SIDER_WIDTH)} !important;
  }
`

type StyledMenuItemProps = {
  space: string
  $isDisabled?: boolean
  $isHidden?: boolean
  $isAbsolute?: boolean
}

export const StyledMenuItem = styled(Item)<StyledMenuItemProps>`
  display: ${(props) => props.$isHidden && 'none'} !important;

  position: ${(props) => props.$isAbsolute && 'absolute'} !important;
  bottom: ${(props) => props.$isAbsolute && 0} !important;

  margin-bottom: ${(props) => props.space || 0} !important;
  cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};

  &.ant-menu-item span.ant-menu-title-content .ant-typography {
    color: ${(props) => (props.$isDisabled ? Color.gray : Color.darkBlue)};
    font-size: ${FontSize.sm} !important;
  }

  &.ant-menu-item a {
    color: ${Color.darkBlue} !important;
    font-size: ${FontSize.sm} !important;
  }

  &.ant-menu-item.ant-menu-item-selected {
    border: none;
    background-color: #b5d7ff;
    color: ${Color.darkBlue};
    display: flex;
    align-items: center;
  }

  & .ant-menu-item-icon {
    font-size: ${FontSize.lg};
    color: ${(props) => (props.$isDisabled ? Color.gray : Color.darkBlue)} !important;
  }
`

// ********** Styled Sider ********** //
